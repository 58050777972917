jQuery(document).ready(function($) {
	// When one of the target classes is clicked ...
	$('.tabbed-nav-tab-nav-link').click(function( event ){
		event.preventDefault();
		// Each container has a data attribute storing the id, which is just the counter from the template
		var tab_id = $(this).attr('data-tabbed-nav-tab');
		// console.log('tab_id=' + tab_id);
		container = $(this).closest('.tabbed-nav-wrapper');
		if ( !$(this).hasClass('active') ) {
			// Remove the active class from all navigation items.
			container.find('.tabbed-nav-tab-nav-link').removeClass('active');
			// Remove the active class from the content areas
			$('.tabbed-nav-tab-content-single-wrapper').removeClass('active');
			// Add the active class to the clicked tab nav item.
			$(this).addClass('active');
			// For the clicked tab, add the active class and show it.
			container.find("#"+tab_id).addClass('active');
		}
		// On mobile only, change the positioning so all the buttons are visible
		if($(window).width() <= 600){ 
			$(this).parent().toggleClass('open');
			container.find('.tabbed-nav-tab-nav-link').removeClass('btn-transparent');
		}
	});

}); /* end of as page load scripts */